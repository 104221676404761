<template>
  <div class="daily-info three reverse" v-if="model">
    <div class="item">
      <!-- <div class="image fixed-image">
        <img class="effect3" :src="imagePath" />
      </div> -->
      <div class="item-text">
        <span>
          <small>HAVA<br />DURUMU</small>
        </span>
        <span>{{ model.weatherValue }}°</span>
      </div>
      <div class="image">
        <img :src="imagePath" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'weather4',
  props: ['data'],
  computed: {
    model() {
      return this.$store.state.weather;
    },
    imagePath() {
      return `/images/weather/${this.model.weatherIcon}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.daily-info {
  .item {
    .item-text {
      margin-top: 6px;
      margin-right: 4px;

      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 46px;

        small {
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
